import * as React from 'react';

export class Name extends React.Component {
  render() {
    return (
      <div
        style={{ marginTop: '50px', marginBottom: '10px', textAlign: 'center' }}
      >
        <span style={{ fontSize: '2em' }}>gott </span>
        <a href="https://twitter.com/_gott" target="_blank">
          @_gott
        </a>
      </div>
    );
  }
}
